import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Ability } from "@casl/ability";
import { PremiumAccessService } from "../../shared/services/premium-access.service";

@Injectable()
export class CanActivateForComponents implements CanActivate {
  constructor(
    private ability: Ability,
    private router: Router,
    private premiumAccessService: PremiumAccessService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const components = route.data.components as Array<string>;
    let canActive = false;

    if (components) {
      components.forEach(c => {
        if (this.ability.can('access', c)) {
          canActive = true;
        }
      });

    }

    if (!canActive) {
      if ((this.router.url !== '/dashboard') && (this.router.url !== '/dashboard/account') && (this.router.url !== '/dashboard/blog')) {
        this.router.navigateByUrl('/dashboard');

      }
      this.premiumAccessService.openPremiumContentModal();
    }

    return canActive;
  }
}
